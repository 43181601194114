<template>
  <div class="text-center">
    <banner-section location="shaniv_packages-top"/>
    <div class="content-block">
      <p class="hotdeal-title fs23">{{ $t('Hot sales') }}</p>
      <router-link
        :to="{
          name: 'CartM',
        }"
        tag="a"
        exact
      >
        <img
          src="/new-assets/icon/long-arrow-left-light.svg"
          alt=""
          class="left-arrow pointer"
        />
      </router-link>
      <div v-for="(item, index) in package_list" :key="index">
        <ProductCart :cartData="item" @addToCart="addToCart($event)" />
      </div>
    </div>
    <!-- ----- cart modal ----------- -->
    <modal
      :width="550"
      :adaptive="true"
      class="cartModal CEModal modal-scroll-bar"
      name="cartModal"
    >
      <div class="modal-header">
        <img
          src="/assets/img/icons/check-circle-light.svg"
          class="check-icon"
          alt
        />
        <h4>{{ $t('Added to cart') }}</h4>
        <span class="fs16">( {{ tempProduct.total_unique_count }} {{ $t('Items') }} )</span>
        <button class="button modal-close_btn" @click="closeCartModal()">
          <img src="/assets/img/icons/times-light-orange.svg" alt />
        </button>
      </div>
      <div class="modal-content d-flex">
        <img
          :src="tempProduct.image || '/new-assets/img/Component 11 – 1.png'"
          class="product-image"
          alt=""
        />
        <div class="mx-15">
          <h4 class="mb-5">{{ tempProduct.name }}</h4>
          <!-- <p class="mb-5 fs16">{{ tempProduct[currentLang + '_description'] }}</p> -->
          <div class="d-flex mb-7 fs16">
            <p>
              <b>{{ $t('Content') }}: </b>
              <span> 5L</span>
            </p>
            <p class="mx-10">
              <b>{{ $t('Quantity') }}: </b>
              <span> {{ tempProduct.added_quantity }}</span>
            </p>
          </div>
          <div class="d-flex fs20 weight-700">
            <p>
              <span>{{ tempProduct.DISCOUNT_VATPRICE }} </span>
              <span class="fs16">₪</span>
            </p>
            <p v-if="tempProduct.DISCOUNT_VATPRICE < tempProduct.VATPRICE" class="mx-10 default-price">
              <span>{{ tempProduct.VATPRICE }} </span>
              <span class="fs16">₪</span>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer mb-5">
        <p class="weight-700 mt-5">
          <span class="fs16">{{ $t('Total added to shopping cart') }}: </span>
          <span class="fs20"
            >{{ tempProduct.added_total_price }}
          </span>
          <span>₪</span>
        </p>
        <div class="text-center">
          <p class="weight-700 color-orange">
            <span class="fs14">{{ $t('Total in cart') }}: </span>
            <span>{{ tempProduct.total_price }} </span>
            <span class="fs14">₪</span>
          </p>
          <router-link to="/m/cart" class="text-decoration-none">
            <button class="primary-btn to-office_btn" @click="closeCartModal()">
              {{ $t('To the box office') }}
            </button>
          </router-link>
        </div>
      </div>
    </modal>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ProductCart from '../../../components/ProductCart';
import BannerSection from "../../../components/BannerSection";

export default {
  name: 'PackagesM',
  components: {
    BannerSection,
    ProductCart,
    Loading
  },
  data: () => {
    return {
      tempProduct: {}
    };
  },
  methods: {
    ...mapActions('productShaniv', {
      getProducts: 'getProducts',
    }),
    ...mapActions('shaniv_management', {
        getPackageList: 'getPackageList'
    }),
    ...mapActions('cartSystem', {
      initCart: 'initCart',
    }),
    addToCart(eachData) {
      this.tempProduct = Object.assign({}, eachData);
      this.$modal.show('cartModal');
    },
    closeCartModal() {
      this.$modal.hide('cartModal');
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.shaniv_management.loading,
      package_list: (state) => state.shaniv_management.package_list,
      cart: state => state.cartSystem.cart
    }),
  },
  async created(e) {
    await this.getPackageList({'active': true});
    this.initCart();
  },
};
</script>

<style lang="scss" scoped>
.content-block {
  background: #eeeeee;
  box-shadow: 0px -3px 6px #00000042;
  min-height: calc(100vh - 60px);
  padding: 10px;
  position: relative;
}
.left-arrow {
  position: absolute;
  right: 20px;
  top: 30px;
}

.cartModal .modal-content .product-image {
  max-width: 100px;
  max-height: 150px;
  object-fit: contain;
}
.cartModal .text-center {
  height: auto !important;
}
</style>